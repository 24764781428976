@import url("https://fonts.googleapis.com/css2?family=Nunito&family=Roboto:wght@400;800&display=swap");

:root {
  --bg-color: #f6f5f7;
  --main-color: #6dd5ed;
  --secondary-color: #2193b0;
  --border-color: #ddd;
  --social-icon-color: #333;
  --white: #fff;
  --input-color: #eee;
  --gradient: linear-gradient(
    135deg,
    var(--main-color),
    var(--secondary-color)
  );
  --shadow:  box-shadow: 0 0 80px rgba(0, 0, 0, 0.2);
  --footer-bg: #333;
  --dark: #222;
  --pnf-bg: #f6f6f6;

  --font-family: "Nunito", sans-serif;
  --roboto: "Roboto", sans-serif;
  --link: gold;
  --shadow-1:box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --nav-shadow: box-shadow: 0 4px 2px -2px rgba(0,0,0,.2);
  --radial-gradient:radial-gradient(#006db3, #29b6f6)
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  min-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-weight: bold;
  margin: 0;
}

p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0px 30px;
}

/* media */

@media screen and (max-width: 996px) {
  body {
    width: 100%;
  }
}
