#home {
  width: 100%;
  height: 100%;
  background: var(--white);
  overflow-x: hidden;
}

#home .container {
  width: 100%;
  height: 100%;
  margin: auto;
  display: block;
}

#home .circle {
  position: absolute;
  border-radius: 50%;
  background: var(--radial-gradient);
}

#home .circles {
  position: absolute;
  height: 270px;
  width: 470px;
}

/* --------------- home section --------------------- */

#home .container .course-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

#home .container .course-heading .content-container {
  flex: 1;
  align-items: center;
  justify-content: center;
}

#home .container .course-heading .content-container h4 {
  font-size: 2em;
}

#home .container .course-heading .content-container h1 {
  font-size: 4em;
  color: var(--main-color);
}

#home .container .course-heading .content-container p {
  font-size: 1.8em;
  line-height: 1.3em;
  font-style: italic;
  color: var(--dark);
}

#home .container .course-heading .course-image {
  flex: 1;
  height: 100vh;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  background-image: url("https://res.cloudinary.com/dfshihuf9/image/upload/v1695527721/Images/freelancer_dymog4.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
}

/* ---------------------study section --------------------------------------- */
.container .study-container {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background: var(--bg-color);
}

.container .study-container .study-row {
  background-color: transparent;
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: start;
}

.container .study-container .study-content {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.container .study-container .study-image {
  flex: 2;
}

.container .study-container .study-image {
  height: 100vh;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("https://res.cloudinary.com/dfshihuf9/image/upload/v1695536036/Images/study-img_ybddt4.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80%;
}

#home .container .study-container h4 {
  font-size: 2em;
}

#home .container .study-container h1 {
  font-size: 4em;
  color: var(--main-color);
}

#home .container .study-container p {
  font-size: 1.8em;
  line-height: 1.3em;
  font-style: italic;
  color: var(--dark);
}

/* -------------- why gurukula --------------------------- */
#home .container .why-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  overflow: hidden;
}

#home .container .why-container .why-row {
  width: 90%;
  height: 600px;
  margin: auto;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

#home .container .why-container .why-row .why-content {
  flex: 2;
  gap: 80px;
  display: flex;
  flex-direction: column;
}

#home .container .why-container .why-row .why-image {
  flex: 1;
  background: url("https://res.cloudinary.com/dfshihuf9/image/upload/v1695544901/Images/why_imbmxw.svg");
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 95%;
  background-position: center right;
  object-fit: cover;
  object-position: center;
}

#home .container .why-container .why-row .why-content h1 {
  color: var(--main-color);
  font-size: 4rem;
}

.container .why-container .why-row .why-content .why-block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
}

.container .why-container .why-row .why-content .why-block div {
  padding: 20px;
  background: var(--white);
  box-shadow: var(--shadow);
  border: 1px solid var(--main-color);
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.container .why-container .why-row .why-content .why-block div::after {
  content: "";
  height: 60px;
  width: 60px;
  background: var(--gradient);
  border: 4px solid var(--white);
  outline: 1px solid var(--main-color);
  border-radius: 50%;
  margin: 2px;
  position: absolute;
  top: -20px;
  right: -20px;
}

/* media query */
@media screen and (max-width: 768px) {
  #home {
    width: 100%;
    height: 100%;
  }
  #home .container {
    height: 100%;
    width: 100%;
    margin: auto;
  }

  #home .container .course-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }

  .container .study-container {
    height: 100%;
  }

  #home .container .course-heading .content-container,
  .container .study-container .study-content {
    width: 100%;
    height: 100%;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  #home .container .why-container .why-row {
    width: 100%;
    height: 100%;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  #home .container .course-heading .course-image,
  .container .study-container .study-image,
  #home .container .why-container .why-row .why-image {
    display: none;
  }

  #home .container .why-container .why-row .why-content {
    height: 100%;
  }

  #home .container h1 {
    font-size: 2.5rem !important;
    text-align: center;
  }

  #home .container h4 {
    text-align: center !important;
    font-size: 1.2rem !important;
  }

  #home .container p {
    text-align: center !important;
    font-size: 0.8rem !important;
  }

  #home .container .why-container .why-row .why-content .why-block {
    width: 100%;
    max-height: 200%;
    display: flex;
    flex-wrap: wrap;
  }

  #home .container .why-container .why-row .why-content .why-block div {
    width: 100%;
  }
}
