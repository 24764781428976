
#auth {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

#auth > .container{
    background-color: var(--bg-color);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 90%;
    width: 100%;
    margin: 1rem 0;
}


.social-container {
    margin: 20px 0;
}

.social-container a {
    border: 1px solid var(--border-color);
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
    text-decoration: none;
    color: var(--social-icon-color);
    font-size: 14px;
    transition: all 0.3s ease-in;
}

.social-container a:hover {
    background-color: var(--main-color);
    color:var(--white);
    border: 1px solid --main-color;
}

.social-container span {
    font-size: 12px;
}

 .btn-login {
    cursor: pointer;
    border-radius: 20px;
    border: 1px solid var(--main-color);
    background: var(--main-color);
    color: var(--white);
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-out;
}

.sign-in-container .btn-login:hover {
    background-color: var(--secondary-color);
}

.btn-login:active {
    transform: scale(0.95);
}

.btn-login:focus {
    outline: none;
}

.overlay-container .ghost {
    background-color: transparent;
    border-color: var(--white);
    text-decoration: none;
}

.overlay-container .ghost:hover {
    background: var(--white);
    color: var(--secondary-color);
}


 .logo-img {
    width: 200px;
}

form {
    background: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
}

input {
    background-color: var(--input-color);
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
    font-family: inherit;
}

a {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
  }

a:hover {
    color:var(--main-color);
}

#auth .container {
    display: flex;
    flex-direction: row;
    background: var(--white);
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    height: 70vh;
    width: 60%;
}


.form-container {
    width: 50%;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.sign-in-container {
    height: 100%;
    z-index: 2;
}

.overlay-container {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}

.overlay-container .overlay {
    background: var(--secondary-color);
    background: var(--gradient);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: var(--white);
    position: relative;
    /* top: -100%; */
    width: 100%;
    height: 100%;
    transform: translateY(0);
    transition:transform 0.6s ease-in-out;
}

.overlay-container .overlay .overlay-panel {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    width: 100%;
    height: 100%;
    transform: translateY(0);
    transition: transform 0.6s ease-in-out;
}

@media screen and (max-width:998px) {
    #auth {
        display: flex;
        height: 100vh;
        width: 100%;
    }
    #auth .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .form-container,
    .overlay-container {
        width: 100%;
        height: 100%;
    }

    .form-container {
        padding: 20px;
    }

    .overlay {
        padding: 20px;
    }

}