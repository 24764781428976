#header {
  width: 100%;
  height: 100%;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  scroll-behavior: smooth;
  scroll-margin-top: 50px;
  box-shadow: var(--nav-shadow);
  z-index: 999;
}

#header .offer {
  background: var(--gradient);
  width: 100%;
  margin: auto;
  padding: 2px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  overflow: hidden;
  color: var(--white);
  box-shadow: var(--nav-shadow);
}

#header .text-call .link {
  color: var(--link);
}

#header .menu-block {
  background-color: var(--white);
  box-shadow: var(--nav-shadow);
}
