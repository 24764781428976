#footer {
  height: 100%;
  width: 100%;
  background-color: var(--footer-bg);
  background-image: url("https://res.cloudinary.com/dfshihuf9/image/upload/v1695524279/Images/World-Map-PNG-Image-File_oodnya.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#footer .img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  padding-top: 80px;
}

#footer h3 {
  color: var(--main-color);
}

#footer .footer-container {
  max-width: 90%;
  margin: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}

.footer-container .about {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-container .about p {
  color: var(--white);
  font-size: 14px;
}

.about .form {
  background-color: inherit;
}

.footer-menu {
  display: flex;
  flex-direction: column;
}

.footer-menu a {
  color: var(--white);
  font-size: 14px;
  font-weight: 700;
}

.footer-menu a:hover {
  color: var(--main-color);
}

/* footer label */

#footer hr {
  width: 80%;
  margin: auto;
}

.footer-label {
  width: 80%;
  margin: auto;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: space-between;
}

.footer-label .social-container a {
  color: var(--main-color);
}

.footer-label .social-container a:hover {
  color: var(--white);
  border: 1px solid var(--main-color);
}

/* media query */
@media screen and (max-width: 998px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-flow: row wrap;
    gap: 40px;
  }
}
