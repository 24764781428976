.menu-container {
  width: 90%;
  margin: auto;
  height: 100%;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: var(--shadow);
}

.menu-container .logo-img {
  width: 150px;
  height: 90px;
  display: flex;
  align-items: center;
}

.menu-container .logo-img img {
  max-width: 100%;
  height: fit-content;
}

/* menu right */

.menu-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-menu,
.profile {
  display: flex;
  align-items: center;
  gap: 10px;
  list-style: none;
}

.nav-menu .nav-item {
  position: relative;
}

.nav-menu .nav-link,
.profile .nav-link {
  font-size: 1em;
  font-weight: 600;
}

.menu-container .nav-toggler {
  padding: 10px;
  display: flex;
  gap: 3px;
  flex-direction: column;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.nav-toggler div {
  height: 5px;
  width: 30px;
  background-color: var(--main-color);
  margin-top: 4px;
}

.menu-container .nav-toggler {
  /* visibility: hidden; */
  display: none;
  opacity: 0;
}

/* sub menu */
.dropdown-menu {
  width: 10rem;
  display: block;
  position: absolute;
  top: 55px;
  left: -50px;
  background-color: var(--dark);
  border-top: 4px solid var(--main-color);
  visibility: hidden;
  opacity: 0;
}

.dropdown-menu li {
  margin-left: -20px;
  text-align: center;
  width: 100%;
  list-style: none;
  border-bottom: 1px solid var(--main-color);
}

.dropdown-menu li:last-child {
  border-bottom: none;
}

.dropdown-menu li a {
  width: 100%;
  display: block;
  font-weight: 400;
  color: var(--main-color);
}

.dropdown-menu.active {
  visibility: visible;
  opacity: 1;
  transition: all 0.5s;
}

/* media query */

@media screen and (max-width: 768px) {
  .menu-container {
    position: relative;
  }

  .menu-container .nav-toggler {
    /* visibility: visible; */
    display: block;
    opacity: 1;
  }

  .menu-container .menu-right {
    display: block;
    position: absolute;
    background-color: var(--dark);
    top: 90px;
    right: 0px;
    width: 100%;
    visibility: hidden;
  }

  .menu-right .nav-menu,
  .menu-right .profile {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 10px;
  }

  .nav-item a {
    color: var(--main-color);
  }

  .menu-container .menu-right.active {
    visibility: visible;
    transition: 0.5s ease-in;
  }

  .dropdown-menu {
    top: 5px;
    left: 80px;
  }
}
